/* ==============
  Maps
===================*/

.gmaps, .gmaps-panaroma {
  height: 300px;
  background: $gray-100;
  border-radius: 3px;
}

.gmaps-overlay {
  display: block;
  text-align: center;
  color: $white;
  font-size: 16px;
  line-height: 40px;
  background: $primary;
  border-radius: 4px;
  padding: 10px 20px;
}

.gmaps-overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.gmaps-overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid $primary;
}

.gmaps-overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid $primary;
}


// Vector Map Height
.vector-map-height {
  height: 400px;
}


.jvectormap-zoomin, .jvectormap-zoomout {
  display: none;
}