/* ==============
  Form Wizard
===================*/
.form-wizard-wrapper {
  label {
    font-size: 14px;
    text-align: right;
  }
}
.wizard>.steps a,
.wizard>.steps a:active,
.wizard>.steps a:hover {
  margin: 3px;
  padding: 15px;
}

.wizard>.steps .current a,
.wizard>.steps .current a:active,
.wizard>.steps .current a:hover {
  background-color: $primary;
  color: $white;
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:active,
.wizard>.steps .disabled a:hover,
.wizard>.steps .done a,
.wizard>.steps .done a:active,
.wizard>.steps .done a:hover {
  background-color: lighten($primary,30%);
  color: $primary;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:active .number,
.wizard>.steps .current a:hover .number {
  border: 2px solid $white
}

.wizard>.steps .disabled a .number,
.wizard>.steps .disabled a:active .number,
.wizard>.steps .disabled a:hover .number,
.wizard>.steps .done a .number,
.wizard>.steps .done a:active .number,
.wizard>.steps .done a:hover .number {
  border-color: $primary;
}

.wizard>.content {
  background-color: transparent;
  margin: 0 5px;
  border-radius: 0;
  min-height: 150px
}

.wizard>.content>.body {
  width: 100%;
  height: 100%;
  padding: 30px 0 0;
  position: static
}

.wizard>.steps .number {
  font-size: 16px;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid $white;
  width: 38px;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  margin-right: 10px;
  background-color: rgba($primary, 0.25);
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:active,
.wizard>.actions .disabled a:hover {
  opacity: .65;
  background-color: $primary;
  color: $white;
  cursor: not-allowed;
}

.wizard>.actions a,
.wizard>.actions a:active,
.wizard>.actions a:hover {
  background-color: $primary;
  border-radius: 4px;
  padding: 8px 15px;
}

@media (max-width: 768px) {
  .wizard > .steps > ul > li {
    width: 50%;
  }
  .form-wizard-wrapper {
    label {
      text-align: left;
    }
  }
}
@media (max-width: 520px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
}
