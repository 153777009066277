

/* ==============
  directory
===================*/

.directory-card{
  .social-links{
    li{
      a{
        margin-bottom: 6px;
      }
    }
  }
}