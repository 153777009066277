
// 
// Progress
//

.progress {
  height: 10px;
}

.progress-bar {
  background-color: $primary;
}